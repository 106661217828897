export default {
  // Temp fonts
  fonts: {
    title: "Roboto, sans-serif",
    main: "Roboto, sans-serif"
  },
  // Colors for layout
  colors: {
    primary1: "#FFCCCB", // Light Red for primary elements
    background1: "#0F1624", // Dark background
    accent1: "#FF6347", // Tomato for accent
    button: "#FF4500", // OrangeRed for buttons
    background2: "#FFFFFF", // White for secondary background
  },
  // Breakpoints for responsive design
  breakpoints: {
    sm: 'screen and (max-width: 640px)',
    md: 'screen and (max-width: 768px)',
    lg: 'screen and (max-width: 1024px)',
    xl: 'screen and (max-width: 1280px)'
  },
}
